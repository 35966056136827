import { IRecord } from './Record';

/**
 * Error code enum from Maestro Service
 * src/main/java/com/adobe/maestro/api/response/ErrorCode.java
 */
export enum MaestroErrorCode {
  BAD_REQUEST = 40000,
  INVALID_DATA = 40001,
  INVALID_DISPLAYNAME_CHARACTERS = 40003,

  UNAUTHORIZED = 40100,

  FORBIDDEN = 40300,
  NO_ACCESS = 40301,
  WORKSPACE_LIMIT_EXCEEDED = 40302,
  RECORD_TYPE_LIMIT_EXCEEDED = 40303,
  FIELD_LIMIT_EXCEEDED = 40304,
  ALLOW_NEGATIVE_CHANGE_DISALLOWED = 40306,
  ALLOW_MULTIPLE_USERS_CHANGE_DISALLOWED = 40307,
  PERSONAL_VIEW_LIMIT_EXCEEDED = 40308,
  FIELD_LOOKUP_DEPTH_LIMIT_EXCEEDED = 40309,
  BACK_REFERENCE_RECORD_ALREADY_CONNECTED = 40310,
  PARAGRAPH_FIELD_LIMIT_REACHED = 40311,

  CUSTOMER_RECORD_LIMIT_EXCEEDED = 40340,
  WORKSPACE_RECORD_LIMIT_EXCEEDED = 40341,
  RECORD_TYPE_RECORD_LIMIT_EXCEEDED = 40342,
  CIRCULAR_DEPENDENCY = 40312,

  NOT_FOUND = 40400,
  ENTITY_WITH_ATTRIBUTE_NOT_FOUND = 40401,

  INTERNAL_SERVER_ERROR = 50000,
}

export interface ILocalizableMessage {
  key: string;
  fallback: string;
  args: Array<string | number>;
  conflictList: Array<{
    id: IRecord['id'];
    value: string;
    connectedRecords: Array<{
      id: string;
      value: string;
    }>;
  }> | null;
}
