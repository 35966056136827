import BaseCrudApi from './BaseCrudApi';
import { IUser } from '../types';
import { ApiClientFetchOptions } from './AbstractApiClient';

export default class UserAPI extends BaseCrudApi<IUser> {
  protected ENDPOINT = 'users';

  async searchUsers(
    userName?: string,
    userIds?: string[],
    fetchOptions?: ApiClientFetchOptions
  ): Promise<IUser[]> {
    const params = new URLSearchParams();
    if (userName !== undefined) {
      params.set('name', userName);
    }
    if (userIds?.length) {
      params.set('ids', userIds.join(','));
    }
    return this.client.get<IUser[]>(`${this.ENDPOINT}?${params.toString()}`, fetchOptions);
  }
}
